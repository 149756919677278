/*! 🧮🧩 2021*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
//import { gsap } from 'gsap';

import Landing from '../vue/landing-page.vue';

import routes from './routes';
import store from './store';
import moduleLoader from './util/moduleLoader';
import vuetify from './vuetify';
import './autoload/smoothScroll';
//import burger from './modules/burger';
//import './util/scroll';

moduleLoader.scan(document.body);

const element = document.querySelector('#app');
if (element) {
    Vue.use(Vuex);
    Vue.use(VueRouter);
    //Vue.use(CloudflareVideoPlayer);
    //Vue.component('CloudflareVideoPlayer', () => import(/* webpackChunkName: "video" */ '../vue/components/CloudflareVideoPlayer.vue'));

    //Vue.component('hero', () => import(/* webpackChunkName: "hero" */ '../vue/components/hero.vue'));
    //Vue.component('screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/screen.vue'));
    //Vue.component('mobile-screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/mobile-screen.vue'));
    //Vue.component('desktop-screen', () => import(/* webpackChunkName: "screen" */ '../vue/components/desktop-screen.vue'));

    const documentLinks = document.querySelectorAll('a.js-router');

    const router = new VueRouter({
        mode: 'history',
        routes,
    });

    // This callback runs before every route change, including on page load.
    router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
        const nearestWithTitle = [...to.matched]
            .reverse()
            .find(r => r.meta && r.meta.title);

        // Find the nearest route element with meta tags.
        const nearestWithMeta = [...to.matched]
            .reverse()
            .find(r => r.meta && r.meta.metaTags);
        /*         const previousNearestWithMeta = from.matched
            .slice()
            .reverse()
            .find(r => r.meta && r.meta.metaTags); */

        // If a route with a title was found, set the document (page) title to that value.
        if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

        // Remove any stale meta tags from the document using the key attribute we set below.
        [...document.querySelectorAll('[data-vue-router-controlled]')].map(element_ => element_.remove());

        // Skip rendering meta tags if there are none.
        if (!nearestWithMeta) return next();

        // Turn the meta tag definitions into actual elements in the head.
        for (const tag of nearestWithMeta.meta.metaTags.map(tagDeferred => {
            const tag = document.createElement('meta');

            for (const key of Object.keys(tagDeferred)) {
                tag.setAttribute(key, tagDeferred[key]);
            }

            // We use this to track which meta tags we create, so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        }))
            document.head.appendChild(tag);

        next();
    });

    router.afterEach(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
        store.commit('setBurger', false);
    });

    for (const documentLink of documentLinks) {
        documentLink.addEventListener('click', event => {
            event.preventDefault();
            const link = event.currentTarget.getAttribute('href');
            if (router.currentRoute.path !== link) {
                router.push(link);
            }
        });
    }

    new Vue({
        el: element,
        render: h => h(Landing),
        router,
        store,
        vuetify,
    });
}

/*
window.addEventListener('beforeunload', function () {
    window.scrollTo(0, 0);
});
*/

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
