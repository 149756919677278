/*! 🧮🧩 2021*/
import LoadingComponent from '../vue/loading-page.vue';
import ErrorComponent from '../vue/loading-error.vue';
//import NotFound from '../vue/NotFound.vue';

const delay = 100;
const timeout = 10000;

const lazyLoadView = ({ component, loading, error, delay, timeout }) => {
    const AsyncHandler = () => ({
        component,
        loading,
        error,
        delay,
        timeout,
    });

    return () => Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        },
    });
};

const home = lazyLoadView({
    component: import('../vue/home-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const charities = lazyLoadView({
    component: import('../vue/charities-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const music = lazyLoadView({
    component: import('../vue/music-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const bedtime = lazyLoadView({
    component: import('../vue/bed-time-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});
const booktour = lazyLoadView({
    component: import('../vue/book-tour-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const news = lazyLoadView({
    component: import('../vue/news-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const about = lazyLoadView({
    component: import('../vue/about-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const photos = lazyLoadView({
    component: import('../vue/photos-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const live = lazyLoadView({
    component: import('../vue/live-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

const videos = lazyLoadView({
    component: import('../vue/videos-page.vue'),
    loading: LoadingComponent,
    error: ErrorComponent,
    delay,
    timeout,
});

export default [
    {
        path: '/',
        component: home,
        meta: { title: 'Paloma Faith | Official Website' },
    },
    {
        path: '/charities/',
        component: charities,
        meta: { title: 'Charities | Paloma Faith | Official Website' },
    },
    {
        path: '/palomasbedtime/',
        component: bedtime,
        meta: { title: 'Bedtime | Paloma Faith | Official Website' },
    },
    {
        path: '/book-tour/',
        component: booktour,
        meta: { title: 'Book Tour | Paloma Faith | Official Website' },
    },
    {
        path: '/music/',
        component: music,
        meta: { title: 'Music | Paloma Faith | Official Website' },
    },
    {
        path: '/news/',
        component: news,
        meta: { title: 'News | Paloma Faith | Official Website' },
    },
    {
        path: '/news/article/',
        component: news,
        meta: { title: 'News | Paloma Faith | Official Website' },
    },
    {
        path: '/about/',
        component: about,
        meta: { title: 'About | Paloma Faith | Official Website' },
    },
    {
        path: '/photos/',
        component: photos,
        meta: { title: 'Photos | Paloma Faith | Official Website' },
    },
    {
        path: '/live/',
        component: live,
        meta: { title: 'Live | Paloma Faith | Official Website' },
    },
    {
        path: '/videos/',
        component: videos,
        meta: { title: 'Videos | Paloma Faith | Official Website' },
    },
    {
        path: '*',
        component: home,
    },
];
