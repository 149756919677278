/*! 🧮🧩 2021*/
import Vue from 'vue';
import Vuetify, {VImg} from 'vuetify/lib';

Vue.use(Vuetify, {components: {VImg}});

// eslint-disable-next-line object-curly-newline
const options = {
    /*theme: {
        themes: {
            light: {
                primary: '#dd0023',
                secondary: '#1f1f1f',
                accent: '#dd0023',
                success: '#dd0023',
            },
        },
    },
    icons: {iconfont: 'mdiSvg'},*/
// eslint-disable-next-line object-curly-newline
};

export default new Vuetify(options);