/*! 🧮🧩 2021*/
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const getOffset = function(element) {
    let _x = 0;
    let _y = 0;
    while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
        _x += element.offsetLeft - element.scrollLeft;
        _y += element.offsetTop - element.scrollTop;
        element = element.offsetParent;
    }
    return {
        top: _y,
        left: _x,
    };
};

const scrollCheck = function(event) {
    const $offset = 30;
    const link = event.currentTarget;
    let hash = typeof link.getAttribute != 'undefined' ? link.getAttribute('href'): location.hash;
    hash = hash.replace(/^\.\//, '');
    const element = document.querySelector(hash);
    if (element) {
        const top = getOffset(element).top - $offset;

        window.scrollTo({
            top,
            behavior: 'smooth',
        });

        history.pushState({}, false, hash);
    } else {
        location.reload();
    }
};

//const $isAnimate = document.querySelector('.is-animate--intro');
const $asideCheck = document.querySelector('.js-aside');
const $burger = document.querySelectorAll('.js-burger');
//if ($isAnimate) {
//window.addEventListener('hashchange', scrollCheck);

window.addEventListener('popstate', scrollCheck);

const $smoothScroll = document.querySelectorAll('.js-smoothScroll');

for (const element of $smoothScroll) {
    element.addEventListener('click', event => {
        event.preventDefault();
        for (const element of $smoothScroll) {
            element.classList.remove('is-active');
        }
        scrollCheck(event);
        element.classList.add('is-active');

        if ($asideCheck && $asideCheck.classList.contains('is-active')) {
            $asideCheck.classList.remove('is-active');
            for (const element of $burger) {
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                }
            }
        }
    });
}
//}
